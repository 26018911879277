import "select2"

$(document).on('turbolinks:load', function () {

  var skuField = $("#js--newCalInstrumentSku")
  var customPointsField = $(".js--customPoints")
  var customPointsTargets = $(".js--customPointsTarget")

  // Add select2 on sku field
  skuField.select2({
    theme: "bootstrap",
    tags: true, // allows custom input
    placeholder: "choose a Dickson SKU or add your own",
    allowClear: true,
    // make the select2 box case-insensitive
    // from https://github.com/select2/select2/issues/3566#issuecomment-148663892
    // TODO: Remove this code when running select2 >= 4.0.4 because it was fixed by
    // https://github.com/select2/select2/commit/cb9a90457867ffb14c7b1550bb67e872e0a5c2dd
    matcher: function(params, data) {
      // If there are no search terms, return all of the data
      if ($.trim(params.term) === '') {
        return data;
      }

      // `params.term` should be the term that is used for searching
      // `data.text` is the text that is displayed for the data object
      if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
        return data;
      }

      // Return `null` if the term should not be displayed
      return null;
    },
    createTag: function(params) {
      var term = $.trim(params.term);
      if(term === "") { return null; }

      var optionsMatch = false;

      this.$element.find("option").each(function() {
        if(this.value.toLowerCase().indexOf(term.toLowerCase()) > -1) {
          optionsMatch = true;
        }
      });

      if(optionsMatch) {
        return null;
      }
      return {id: term, text: term};
    }
  });

  var updateCustomFields = function(element) {
    if (element.checked) {
      customPointsTargets.prop("disabled", false)
    } else {
      customPointsTargets.prop("disabled", true)
    }
  }
  customPointsField.on("change", function(e) {
    updateCustomFields(e.target)
  })
  customPointsField.each(function() {
    updateCustomFields(this);
  })
})
